<!--现存统计 By阿旭  -->
<template>
  <div class="main">
    <div class="condition-filtrate-box">
      <div class="left-item">
        <el-select
          class="select-width"
          size="medium"
          v-model="isolationPointId"
          placeholder="隔离点">
          <el-option v-for="item in isolationList" :key="item.id" :label="item.orgName" :value="item.id" />
        </el-select>

        <el-date-picker
          v-model="pickerTime"
          size="medium"
          type="date"
          value-format="yyyy-MM-dd"
          placeholder="选择日期" />
      </div>

      <div class="right-item">
        <el-button type="primary" size="small" @click="queryFormInfo">查询</el-button>
        <el-button type="primary" size="small" @click="resetQueryInfo">重置</el-button>
        <el-button type="primary" size="small" @click="exportFormInfo" v-loading.fullscreen.lock="fullscreenLoading">导出</el-button>
      </div>
    </div>

    <!--现存隔离统计-->
    <div class="cantent-box">
      <h3 class="subtitle">现存隔离统计</h3>

      <div class="row-item">
        <PieChart ref="PieChartRef1" style="width: 50%; height: 300px;" item-id="extant-census" />

        <!--人群分类-->
        <TabelModule :td-list="crowdType" :table-data="typeDataList" />
      </div>
    </div>

    <div class="cantent-box">
      <h3 class="subtitle">国内中高风险地区</h3>

      <div class="row-item">
        <PieChart ref="PieChartRef2" style="width: 50%; height: 300px;" item-id="area-census" />

        <!--地区数据-->
        <TabelModule :td-list="areaType" :table-data="areaDataList" />
      </div>
    </div>
  </div>
</template>

<script>
import { statisticNowRegLive, exportNowExisting } from "../../api/StatisticalAnalysis/dailySummaryStatistics";
import TabelModule from './modules/TabelModule';
import PieChart from './modules/PieChart';
import { messageBox, importFile } from '../../utils/utils';
import { mapState } from "vuex";

export default {
  components: { TabelModule, PieChart },
  data() {
    return {
      userdoctor: JSON.parse(localStorage.getItem("userdoctor")),
      isolationPointId: "",
      pickerTime: "",
      crowdType: [
        { value: 1, label: "人群分类" },
        { value: 2, label: "人数" }
      ],
      areaType: [
        { value: 1, label: "地区" },
        { value: 2, label: "人数" }
      ],
      typeDataList: [],
      areaDataList: [],
      fullscreenLoading: false
    };
  },

  computed: {
    ...mapState({
      isolationList: state => state.user.isolationList
    })
  },

  created() {
    let year = new Date().getFullYear();
    let month = new Date().getMonth() + 1;
    let date = new Date().getDate();
    this.pickerTime = `${year}-${month}-${date}`;

    this.crowdClassify();
  },

  methods: {
    setParamsInfo() {
      let params = {
        orgCode: this.userdoctor.orgCode,
        hospitalId: this.userdoctor.hospitalId
      };

      let { isolationPointId, pickerTime } = this;
      if (isolationPointId) params.isolationPointId = isolationPointId;
      if (pickerTime) {
        params.timeMin = pickerTime + " 00:00:00";
        params.timeMax = pickerTime + " 23:59:59";
      }

      return params;
    },

    // 按人群分类
    async crowdClassify() {
      let params = this.setParamsInfo();

      let { data } = await statisticNowRegLive(params);
      if (data.code == 200) {
        let { areaDataList, typeDataList } = data.data;
        typeDataList.forEach(item => {
          item.label = item.crowdTypeName;
          item.name = item.crowdTypeName;
          item.value = item.peopleCount;
        })
        areaDataList.forEach(item => {
          item.label = item.livingArea;
          item.name = item.livingArea;
          item.value = item.peopleCount;
        })
        this.typeDataList = typeDataList;
        this.areaDataList = areaDataList;

        let extantCensus = typeDataList.slice(0, typeDataList.length - 1);
        let areaCensus = areaDataList.slice(0, areaDataList.length - 1);
        this.$refs.PieChartRef1.getPieceList('现存隔离统计', extantCensus)
        this.$refs.PieChartRef2.getPieceList('国内中高风险地区', areaCensus)
      } else {
        this.$message.error(data.msg)
      }
    },

    queryFormInfo() {
      this.crowdClassify();
    },

    resetQueryInfo() {
      this.isolationPointId = "";
      this.pickerTime = "";
      this.crowdClassify();
    },

    exportFormInfo() {
      let params = this.setParamsInfo();
      messageBox(() => {
        this.fullscreenLoading = true;
        exportNowExisting({params}).then(res => {
          this.fullscreenLoading = false;
          importFile(res.data, '现存统计数据')
        })
      }, "warning", '是否确认导出现存统计数据？')
    }
  }
};
</script>

<style lang='scss' scoped>
@import "../../static/css/condition-filtrate.scss";

.main {
  width: 100%;
  height: 100%;
}

.cantent-box{
  margin-top: 30px;

  &:last-child{
    margin-top: 50px;
    padding-bottom: 40px;
  }

  .row-item{
    display: flex;
    justify-content: space-between;
  }

  .subtitle {
    color: #333333;
    margin-bottom: 16px;
  }
}
</style>
